import React from 'react';
import { Row, Container, Col, Navbar, Offcanvas, Modal, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import heroVideo from './assets/Untitled.mp4';
import heroLogo from './assets/redLightLogo.png'
import scrollDown from './assets/scrollDown.gif'
import self from './assets/self.png'
import figma from './assets/figma.png'
import xd from './assets/xd.png'
import protopie from './assets/protopie.png'
import ps from './assets/ps.png'
import ai from './assets/ai.png'
import html from './assets/html.png'
import css from './assets/css.png'
import javascript from './assets/js.png'
import bootstrapIcon from './assets/bootstrapIcon.png'
import reactIcon from './assets/reactIcon.png'
import reactNativeIcon from './assets/reactNativeIcon.png'
import nodeIcon from './assets/nodeIcon.png'
import awsIcon from './assets/awsIcon.png'
import expressIcon from './assets/expressIcon.png'
import bizDev from './assets/bizDev.png'
import pgtMgt from './assets/pgtMgt.png'
import tableauIcon from './assets/tableauIcon.png'
import smmIcon from './assets/smmIcon.png'
import techm from './assets/clients/00techm.png'
import vp from './assets/clients/01vp.png'
import vecv from './assets/clients/02vecv.png'
import eicher from './assets/clients/03eicher.png'
import maxxis from './assets/clients/04maxxis.png'
import queens from './assets/clients/05queens.png'
import daima from './assets/clients/06daima.png'
import hs18 from './assets/clients/07hs18.png'
import blackberrys from './assets/clients/08blackberrys.png'
import olx from './assets/clients/09olx.png'
import melia from './assets/clients/10melia.png'
import sbarro from './assets/clients/11sbarro.png'
import igr from './assets/clients/12igr.png'
import ford from './assets/clients/13ford.png'
import numeroUno from './assets/clients/14numerouno.png'
import linkedin from './assets/li.png'
import CardCustomizable from './CardCustomizable'
import tm from './assets/cardThumbnails/tm.png'
import etb from './assets/cardThumbnails/etb.png'
import daimaThumbnail from './assets/cardThumbnails/daima.png'
import qg from './assets/cardThumbnails/qg.png'

const App = () => {
  return (
  <>




{/* 
╦ ╦╔═╗╦═╗╔═╗  ╔═╗╔═╗╔═╗╔╦╗╦╔═╗╔╗╔
╠═╣║╣ ╠╦╝║ ║  ╚═╗║╣ ║   ║ ║║ ║║║║
╩ ╩╚═╝╩╚═╚═╝  ╚═╝╚═╝╚═╝ ╩ ╩╚═╝╝╚╝
HERO SECTION
 */}
<Container fluid className="videoBGSection" style={{backgroundColor:"#383838", paddingLeft:"0px", paddingRight:"0px"}} >
<video className="videoBG" src={heroVideo} muted loop autoPlay preload="true"  webkit-playsinline="true" playsinline="true"></video>

{/* 
╔╗╔╔═╗╦  ╦╔╗ ╔═╗╦═╗
║║║╠═╣╚╗╔╝╠╩╗╠═╣╠╦╝
╝╚╝╩ ╩ ╚╝ ╚═╝╩ ╩╩╚═ 
*/}

  <Navbar className="navigationTopBorder navbarGradient" expand={false}>
    <Container >
      <Navbar.Brand href="#"><img
          src={heroLogo}
          width={982/8}
          height={470/8}
          className="d-inline-block align-top"
          alt="Guten tech Logo"
        /></Navbar.Brand>
      <Navbar.Toggle aria-controls="offcanvasNavbar" />
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
      >
        <Offcanvas.Header closeButton className="tertiaryBG">
          <Offcanvas.Title id="offcanvasNavbarLabel" className="bgText"></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="tertiaryBG" style={{height:"100%"}}>
        <Row> 
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
      <h3 className="bgText">Let's put our heads together</h3>
      <h1 className="bgText extraBoldText ">GET CONNECTED</h1>
      <br/>
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} className="">
      <p className="bgText "><span className="boldText ">Phone:</span><br/> +61 452 017 106</p>
      <br/>
      <p className="bgText "><span className="boldText ">Email-id:</span><br/>parrytowsh@gmail.com</p>
      <br/>
      <p className="bgText "><span className="boldText ">Social media:</span></p>
      <a href="https://www.linkedin.com/in/parrytowsh/" target="_blank">
        <img src={linkedin} alt="linkedin" style={{width:"32px", marginTop:"-16px"}} />
      </a>

      </Col>

      </Col>
      </Row>

        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>

{/* 
╔╦╗╔═╗═╗ ╦╔╦╗  ╔═╗╔═╗╔═╗╔╦╗╦╔═╗╔╗╔
 ║ ║╣ ╔╩╦╝ ║   ╚═╗║╣ ║   ║ ║║ ║║║║
 ╩ ╚═╝╩ ╚═ ╩   ╚═╝╚═╝╚═╝ ╩ ╩╚═╝╝╚╝
TEXT SECTION
 */}

  <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="heroSectionText">
      <h3 className="tertiaryText"> Let's create</h3>
      <h1 className="tertiaryText extraBoldText"><span className="primaryText">EMPOWERING</span> USER EXPERIENCES</h1>
      <p className="tertiaryText">Instilling confidence in engaging users and maximizing ROI for businesses through an easy-to-use objective driven interface. </p>
      </Col>
    </Row>
  </Container>

<Container>
  <Row style={{justifyContent:"center"}}> 
    <img src={scrollDown} alt="Scroll down" style={{marginTop:"120px",width:"96px"}} />
  </Row>
  </Container>


</Container>



{/* 
╔═╗╔╗ ╔═╗╦ ╦╔╦╗  ╦ ╦╔═╗
╠═╣╠╩╗║ ║║ ║ ║   ║ ║╚═╗
╩ ╩╚═╝╚═╝╚═╝ ╩   ╚═╝╚═╝
ABOUT US
 */}

<Container fluid className="" style={{overflow:"hidden",height:'fit-content', backgroundColor:"#383838", paddingLeft:"0px", paddingRight:"0px"}} >


{/* 
╔╦╗╔═╗═╗ ╦╔╦╗  ╔═╗╔═╗╔═╗╔╦╗╦╔═╗╔╗╔
 ║ ║╣ ╔╩╦╝ ║   ╚═╗║╣ ║   ║ ║║ ║║║║
 ╩ ╚═╝╩ ╚═ ╩   ╚═╝╚═╝╚═╝ ╩ ╩╚═╝╝╚╝
TEXT SECTION
 */}

  <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className="aboutSectionText">
      <h3 className="tertiaryText"> About</h3>
      <h1 className="tertiaryText extraBoldText"><span className="primaryText">SKILLS</span> ACQUIRED</h1>
      <p className="tertiaryText">The turf of digital is ever expanding and offers new challenges every passing day. Here are some of the key skills picked in the professional journey. </p>
      <br/>
      <p className="tertiaryText boldText">Designing</p>
      <Row>
        <Col>
          <img src={figma} alt="figma" style={{width:"42px", marginRight:"20px"}} />
          <img src={xd} alt="xd" style={{width:"42px", marginRight:"20px"}} />
          <img src={protopie} alt="protopie" style={{width:"42px", marginRight:"20px"}} />
          <img src={ps} alt="photoshop" style={{width:"42px", marginRight:"20px"}} />
          <img src={ai} alt="illustrator" style={{width:"42px", marginRight:"20px"}} />
        </Col>
      </Row>
      <br/>
      <p className="tertiaryText boldText">Front-end Development</p>
        <Row>
        <Col>
          <img src={html} alt="html" style={{width:"42px", marginRight:"20px"}} />
          <img src={css} alt="css" style={{width:"42px", marginRight:"20px"}} />
          <img src={javascript} alt="javascript" style={{width:"42px", marginRight:"20px"}} />
          <img src={bootstrapIcon} alt="bootstrapIcon" style={{width:"42px", marginRight:"20px"}} />
          <img src={reactIcon} alt="react" style={{width:"42px", marginRight:"20px"}} />
          <img src={reactNativeIcon} alt="react native" style={{width:"42px", marginRight:"20px"}} />

        </Col>
      </Row>
      <br/>
      <p className="tertiaryText boldText">Back-end Development & Cloud</p>
      <Row>
        <Col>
          <img src={nodeIcon} alt="nodeIcon" style={{width:"42px", marginRight:"20px"}} />
          <img src={expressIcon} alt="expressIcon" style={{width:"42px", marginRight:"20px"}} />
          <img src={awsIcon} alt="awsIcon" style={{width:"42px", marginRight:"20px"}} />
        </Col>
      </Row>
      <br/>
      <p className="tertiaryText boldText">Other skills</p>
      <img src={bizDev} alt="Business Development" style={{width:"42px", marginRight:"20px"}} />
      <img src={pgtMgt} alt="Project Management" style={{width:"42px", marginRight:"20px"}} />
      <img src={tableauIcon} alt="tableau" style={{width:"42px", marginRight:"20px"}} />
      <img src={smmIcon} alt="Social media" style={{width:"42px", marginRight:"20px"}} />
      </Col>


      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} className="">
      <img src={self} style={{width:"100%", opacity:"0.8", paddingTop:"32px"}} alt="self"/>
      </Col>

      </Row>



{/* 
╔═╗╔═╗╔═╗╔═╗  ╔═╗╔╦╗╦ ╦╔╦╗╦╔═╗╔═╗
║  ╠═╣╚═╗║╣   ╚═╗ ║ ║ ║ ║║║║╣ ╚═╗
╚═╝╩ ╩╚═╝╚═╝  ╚═╝ ╩ ╚═╝═╩╝╩╚═╝╚═╝
CASE STUDIES
 */}
<Row style={{marginTop:"120px"}}>
  <h3 className="tertiaryText">Key</h3>
  <h1 className="tertiaryText extraBoldText"><span className="primaryText">PROJECTS</span></h1>
  <p className="tertiaryText">Some work achieved with the help of a fantastic team.</p>
</Row>


</Container>

</Container>

<Container fluid style={{height:'fit-content', backgroundColor:"#383838", paddingLeft:"0px", paddingRight:"0px"}}>
<Container className="paddingBottom36">
  <Row className="d-flex flex-row flex-nowrap overflow-auto marginLeft1">

      <CardCustomizable 
        imageSource={tm}
        title="Tech Mahindra: Website revamp project"
        description="USD 5.1 billion organization with 126,200+ professionals across 90 countries, helping 1058 global..."
        backgroundColor="transparent"
        borderColor="transparent"
        rightButton="greenButton marginTop24"
        modalNumber="1"
        />
        
        <CardCustomizable 
        imageSource={etb}
        title="Eicher Trucks & Buses: Multiple website projects"
        description="Complete range of Eicher branded trucks and buses, exclusive distribution of Volvo Trucks in India..."
        backgroundColor="transparent"
        borderColor="transparent"
        rightButton="greenButton marginTop24"
        modalNumber="2"
        />

        <CardCustomizable 
        imageSource={daimaThumbnail}
        title="Daima Africa: Website revamp project"
        description="One of the most renowned brands in East Africa, serving primarily in Kenya, Uganda, and Tanzania..."
        backgroundColor="transparent"
        borderColor="transparent"
        rightButton="greenButton marginTop24"
        modalNumber="3"
        />

        <CardCustomizable 
        imageSource={qg}
        title="Queen's Gynecology: New website project"
        description="Created with the aim of providing platform where women can address their gynecological and sexual..."
        backgroundColor="transparent"
        borderColor="transparent"
        rightButton="greenButton marginTop24"
        modalNumber="4"
        />

  </Row>
  </Container>  
</Container>

{/* 
╔═╗╦  ╦╔═╗╔╗╔╔╦╗╔═╗
║  ║  ║║╣ ║║║ ║ ╚═╗
╚═╝╩═╝╩╚═╝╝╚╝ ╩ ╚═╝
CLIENTS
 */}

 <Container fluid className="" style={{height:'fit-content', overflow:"hidden", paddingBottom:"100px", backgroundColor:"#eb3f43", paddingLeft:"0px", paddingRight:"0px"}} >


{/* 
╔╦╗╔═╗═╗ ╦╔╦╗  ╔═╗╔═╗╔═╗╔╦╗╦╔═╗╔╗╔
 ║ ║╣ ╔╩╦╝ ║   ╚═╗║╣ ║   ║ ║║ ║║║║
 ╩ ╚═╝╩ ╚═ ╩   ╚═╝╚═╝╚═╝ ╩ ╩╚═╝╝╚╝
TEXT SECTION
 */}

  <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="aboutSectionText">
      <h3 className="tertiaryText">Some of the clients</h3>
      <h1 className="tertiaryText extraBoldText">WORKED WITH</h1>
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
      <p className="tertiaryText">It is always exciting to work together for achieving business objectives and gaining higher ROI through web development, social media management and other digital projects.  </p>
      </Col>
      <br/>
      <Row>
        <Col>
          <img src={techm} alt="Tech Mahindra" style={{width:"212px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={vp} alt="Virtual Psychologist" style={{width:"212px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={vecv} alt="vecv" style={{width:"350px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={eicher} alt="eicher" style={{width:"178px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={maxxis} alt="maxxis" style={{width:"178px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={queens} alt="queens gynecologist" style={{width:"178px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={melia} alt="melia" style={{width:"278px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={daima} alt="Daima Africa" style={{width:"132px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={hs18} alt="hs18" style={{width:"132px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={olx} alt="olx" style={{width:"132px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={blackberrys} alt="blackberrys" style={{width:"180px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={ford} alt="ford" style={{width:"144px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={numeroUno} alt="numeroUno" style={{width:"144px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={sbarro} alt="sbarro" style={{width:"120px", marginRight:"72px", marginBottom:"60px"}} />
          <img src={igr} alt="igr" style={{width:"144px", marginRight:"72px", marginBottom:"60px"}} />

        </Col>
      </Row>

      </Col>
      </Row>
  </Container>

</Container>


{/* 
╔═╗╔═╗╔╗╔╔╦╗╔═╗╔═╗╔╦╗
║  ║ ║║║║ ║ ╠═╣║   ║ 
╚═╝╚═╝╝╚╝ ╩ ╩ ╩╚═╝ ╩ 
CONTACT
 */}

 <Container fluid className="" style={{overflow:"hidden", height:'fit-content', backgroundColor:"#ffa910", paddingLeft:"0px", paddingRight:"0px"}} >

  <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="aboutSectionText">
      <h3 className="bgText">Let's put our heads together</h3>
      <h1 className="bgText extraBoldText">GET CONNECTED</h1>
      <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
      <p className="bgText"><span className="boldText">Phone:</span> +61 452 017 106</p>
      <p className="bgText"><span className="boldText">Email-id:</span> parrytowsh@gmail.com</p>
      <a href="https://www.linkedin.com/in/parrytowsh/" target="_blank">
        <img src={linkedin} alt="linkedin" style={{width:"32px"}} />
      </a>

      <p className="bgText marginTop72">Copyright © Guten Tech. All Rights Reserved</p>

      </Col>

      </Col>
      </Row>

  </Container>

</Container>


  </>
  )
  };

export default App;

