import React,{useState} from 'react'
import { Card, Row, Col, Modal, Button, Container} from 'react-bootstrap';
import tm from './assets/cardThumbnails/tm.png'
import etb from './assets/cardThumbnails/etb.png'
import daimaThumbnail from './assets/cardThumbnails/daima.png'
import qg from './assets/cardThumbnails/qg.png'

const CardCustomizable = (props) => {

  const [fullscreen, setFullscreen] = useState(true);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  function handleShow(props) {
    setFullscreen("xxl-down");
    if (props === "1") {setShow1(true)}
    else if (props === "2") {setShow2(true)}
    else if (props === "3") {setShow3(true)}
    else if (props === "4") {setShow4(true)}
  }

  return (
    <>
      <Card className="cardStyleCustomizable" style={{ width: '19rem', backgroundColor:props.backgroundColor, borderColor:props.borderColor }}>
        <Card.Img className="cardImage" variant="top" src={props.imageSource} />
        <span className="coverCard" style={{}}></span>
        <Card.Body style={{paddingLeft:"2px", paddingRight:"0px"}}>
        {/* <h4 className="marginBottom12 tertiaryText boldText">by <span className="primaryHover">{props.author}</span></h4> */}
          <Card.Title><h5 className='tertiaryText boldText' >{props.title}</h5></Card.Title>
          <Card.Text>
            <p className='tertiaryText normalText'>{props.description}</p>

          </Card.Text> 

          <Row className="gutterFix marginTop-6">
            <Button key={props.key} onClick={() => handleShow(props.modalNumber)} className={props.rightButton} variant="primary">VIEW DETAILS</Button>
          </Row>
          
        </Card.Body>
      </Card>
{/* 
╔═╗╔═╗╔═╗╔═╗  ╔═╗╔╦╗╦ ╦╔╦╗╦╔═╗╔═╗
║  ╠═╣╚═╗║╣   ╚═╗ ║ ║ ║ ║║║║╣ ╚═╗
╚═╝╩ ╩╚═╝╚═╝  ╚═╝ ╩ ╚═╝═╩╝╩╚═╝╚═╝
TECH M
 */}

<Modal show={show1} fullscreen={fullscreen} onHide={() => setShow1(false)}>
  <Modal.Header style={{borderBottom:"0px", backgroundColor:"#ffa910"}} closeButton>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body style={{backgroundColor:"#ffa910"}}>
    <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className=" caseStudyHeight animateEntry1">
      <h3 className="bgText">Project</h3>
      <h1 className="bgText extraBoldText">Tech Mahindra</h1>
      <p className="bgText">Tech Mahindra offers innovative and customer-centric digital experiences, enabling enterprises, associates and the society to Rise. They are a USD 5.1 billion organization with 126,200+ professionals across 90 countries, helping 1058 global customers including Fortune 500 companies. </p>
      <p className="bgText"><span className="boldText">Project type:</span> Website revamp project</p>
      <p className="bgText"><span className="boldText">Project link:</span> <a href="https://www.techmahindra.com/" target="_blank" rel="noreferrer"> https://www.techmahindra.com/</a></p>
      <p className="bgText"><span className="boldText">Role:</span> Program Manager, UX Designer</p>
      <p className="bgText"><span className="boldText">Location:</span> New Delhi, India</p>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className=" caseStudyImageHeight animateEntry2">
      <img src={tm} alt="tech mahindra" style={{width:"100%", borderRadius:"20px", borderWidth:"2px", borderColor:"white", borderStyle:"solid"}} />
      </Col>

      </Row>
    </Container>  
  </Modal.Body>
</Modal>


<Modal show={show2} fullscreen={fullscreen} onHide={() => setShow2(false)}>
  <Modal.Header style={{borderBottom:"0px", backgroundColor:"#ffa910"}} closeButton>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body style={{backgroundColor:"#ffa910"}}>
    <Container>
    <Row> 
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyHeight animateEntry1">
      <h3 className="bgText">Project</h3>
      <h1 className="bgText extraBoldText">EICHER TRUCKS & BUSES</h1>
      <p className="bgText">The company’s product portfolio includes the complete range of Eicher branded trucks and buses, exclusive distribution of Volvo Trucks in India, engine manufacturing and exports for Volvo Group, non-automotive engines and Eicher component business. A multi-brand, multi-division company, backed by innovative products & services, VECV today, is recognised as an industry leader in CV industry.</p>
      <p className="bgText"><span className="boldText">Project type:</span> Multiple website projects</p>
      <p className="bgText"><span className="boldText">Project link:</span> <a href="https://www.eichertrucksandbuses.com/" target="_blank" rel="noreferrer"> https://www.eichertrucksandbuses.com/</a></p>
      <p className="bgText"><span className="boldText">Project link:</span> <a href="https://www.eichertrucksandbuses.co.za/" target="_blank" rel="noreferrer"> https://www.eichertrucksandbuses.co.za/</a></p>
      <p className="bgText"><span className="boldText">Project link:</span> <a href="https://engines.eicher.in/" target="_blank" rel="noreferrer"> https://engines.eicher.in/</a></p>
      <p className="bgText"><span className="boldText">Role:</span> Project Manager, UX Designer</p>
      <p className="bgText"><span className="boldText">Location:</span> New Delhi, India</p>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyImageHeight animateEntry2">
      <img src={etb} alt="Eicher" style={{width:"100%", borderRadius:"20px", borderWidth:"2px", borderColor:"white", borderStyle:"solid"}} />
      </Col>

      </Row>
    </Container>  
  </Modal.Body>
</Modal>

<Modal show={show3} fullscreen={fullscreen} onHide={() => setShow3(false)}>
  <Modal.Header style={{borderBottom:"0px", backgroundColor:"#ffa910"}} closeButton>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body style={{backgroundColor:"#ffa910"}}>
  <Container>  
  <Row>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyHeight animateEntry1">
      <h3 className="bgText">Project</h3>
      <h1 className="bgText extraBoldText">DAIMA AFRICA</h1>
      <p className="bgText">One of the most renowned brands in East Africa, serving primarily in Kenya, Uganda, and Tanzania, and have gained a competitive edge over other industry players by delivering superior quality products made from handpicked ingredients from the best of the farms. Our products including milk, flavored drinks, yogurts, etc. cater to a wide range of consumers and take care of the African population’s nourishment.</p>
      <p className="bgText"><span className="boldText">Project type:</span> Website revamp project</p>
      <p className="bgText"><span className="boldText">Project link:</span><a href="http://www.daimaafrica.com/" target="_blank" rel="noreferrer"> http://www.daimaafrica.com/</a></p>
      <p className="bgText"><span className="boldText">Role:</span> Project Manager, UX Designer</p>
      <p className="bgText"><span className="boldText">Location:</span> New Delhi, India</p>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyImageHeight animateEntry2">
      <img src={daimaThumbnail} alt="Daima" style={{width:"100%", borderRadius:"20px", borderWidth:"2px", borderColor:"white", borderStyle:"solid"}} />
      </Col>

      </Row>
    </Container>  
  </Modal.Body>
</Modal>

<Modal show={show4} fullscreen={fullscreen} onHide={() => setShow4(false)}>
  <Modal.Header style={{borderBottom:"0px", backgroundColor:"#ffa910"}} closeButton>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body style={{backgroundColor:"#ffa910"}}>
  <Container>  
  <Row>
      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyHeight animateEntry1">
      <h3 className="bgText">Project</h3>
      <h1 className="bgText extraBoldText">QUEEN'S GYNECOLOGY</h1>
      <p className="bgText">Created with the aim of providing platform where women can address their gynecological and sexual well being in an unbiased and unreserved manner and with the vision of empowering women by providing equal health opportunities, where women and girls live in dignity, are healthy and have choices.</p>
      <p className="bgText"><span className="boldText">Project type:</span> New website project</p>
      <p className="bgText"><span className="boldText">Project link:</span><a href="https://www.queensgynecology.in/" target="_blank"> https://www.queensgynecology.in/</a></p>
      <p className="bgText"><span className="boldText">Role:</span> Project Manager, UX Designer</p>
      <p className="bgText"><span className="boldText">Location:</span> New Delhi, India</p>
      </Col>

      <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="caseStudyImageHeight animateEntry2">
      <img src={qg} alt="Queen's Gynecology" style={{width:"100%", borderRadius:"20px", borderWidth:"2px", borderColor:"white", borderStyle:"solid"}} />
      </Col>

      </Row>
    </Container>  
  </Modal.Body>
</Modal>
    </>
  )
}

export default CardCustomizable
